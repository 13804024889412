import { Component } from '@angular/core';

import { ButtonLinkComponent } from '@components/ui/button-link/button-link.component';
import { LogoLinkComponent } from '../logo-link/logo-link.component';
import { ROUTE_HOME } from '@settings/routes';

@Component({
  selector: 'app-not-found',
  imports: [LogoLinkComponent, ButtonLinkComponent],
  templateUrl: './not-found.component.html',
  styleUrl: './not-found.component.scss',
})
export class NotFoundComponent {
  homeLink = ROUTE_HOME;
}
