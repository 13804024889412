import { NgClass, NgIf } from '@angular/common';
import { Component, input } from '@angular/core';

@Component({
  selector: 'app-info-block-announcement',
  imports: [NgClass, NgIf],
  templateUrl: './info-block-announcement.component.html',
  styleUrl: './info-block-announcement.component.scss',
})
export class InfoBlockAnnouncementComponent {
  title = input.required<string>();
  description = input<string>('');
  variety = input<'rejected' | 'moderation' | 'success'>('moderation');
}
